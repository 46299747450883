import React from "react"

import { graphql } from "gatsby"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { JesLayout } from "../../components/jes-layout"
import { StandardParagraph, Bold } from "../../components/blocks"

const DatenschutzENPage = ({ data }) => {
  const { t } = useI18next()

  return (
    <JesLayout title={t("Datenzchutz")}>
      <h1> DISCLAIMER / PRIVACY POLICY</h1>
      <StandardParagraph>
        <Bold>Notes on content</Bold>
      </StandardParagraph>
      <p>
        The JES Kulturstiftung makes every effort to ensure that the information
        and content freely accessible on its website is prepared with the
        greatest possible care. However, it cannot accept any liability for the
        accuracy, completeness and up-to-dateness of this information and
        content, and there is no obligation to monitor transmitted or stored
        third-party information or to investigate circumstances that indicate
        illegal activity. Obligations to remove or block the use of information
        in accordance with general laws remain unaffected by this. However,
        liability in this regard can only exist from the time of knowledge of a
        concrete infringement. Contributions identified by name reflect the
        opinion of the respective author and not always the opinion of the JES
        Kulturstiftung. The mere fact of calling up the free and freely
        accessible information and content does not constitute a contractual
        relationship between the user and the JES Kulturstiftung; in this
        respect, the JES Kulturstiftung's intention to be legally bound is
        lacking in any case.
      </p>
      <StandardParagraph>
        <Bold>External links</Bold>
      </StandardParagraph>
      <p>
        As content provider, the JES Kulturstiftung is responsible for its own
        content, which it makes available for use, in accordance with general
        laws. Links to third-party websites ("external links") are subject to
        the liability of the respective operators. The JES Kulturstiftung checks
        the external content for possible legal violations when the external
        links are first created. The JES Kulturstiftung has no influence on the
        current and future design and content of the linked websites; the
        inclusion of an external link does not imply that the JES Kulturstiftung
        endorses the content and information behind the link. The external links
        are not constantly monitored. However, if the JES Kulturstiftung becomes
        aware of any legal violations, such external links will be deleted
        immediately, insofar as this is technically possible and reasonable.
      </p>
      <StandardParagraph>
        <Bold>Copyright</Bold>
      </StandardParagraph>
      <p>
        The content and works published on this website are subject to German
        copyright law. Any reproduction, editing, dissemination or exploitation
        not permitted thereunder requires the written consent of the JES
        Kulturstiftung or the respective author/creator. Downloads and copies of
        this website are only permitted for private, non-commercial use. Insofar
        as the content on this site was not created by the JES Kulturstiftung,
        the copyrights of third parties are respected. In particular,
        third-party content is identified as such. However, should copyright
        infringements occur, the JES Kulturstiftung will remove such content
        immediately upon becoming aware of it.
      </p>
      <StandardParagraph>
        <Bold>NOTES ON DATA PROCESSING</Bold>
      </StandardParagraph>
      <p>
        The JES Kulturstiftung takes the protection of the personal data of the
        users of its website very seriously and strictly adheres to the rules of
        the data protection laws (DSGVO, Federal Data Protection Act as well as
        the Telemedia Act). Personal data is only collected and processed on
        this website in accordance with these data processing instructions.
        Under no circumstances will the collected data be sold or passed on to
        third parties for other reasons. This information on data processing
        applies to the use of the website at the address{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="http://www.jes-kulturstiftung.de"
        >
          www.jes-kulturstiftung.de
        </a>
        .
      </p>
      <br />
      <h3>A. GENERAL</h3>
      <p>
        **Name and contact details of the controller ** This data protection
        notice applies to data processing by the JES Kulturstiftung, represented
        by the board members Dr. Dr. Jochen Seifert and Ms. Erika Seifert,
        Adolf-MÃ¼nzer-StraÃŸe 9, 86919 Holzhausen / Ammersee, phone: +49 (0)8806
        924115, e-mail:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
        .
      </p>
      <StandardParagraph>
        <Bold>Scope of the processing of personal data</Bold>
      </StandardParagraph>
      <p>
        As a matter of principle, the JES Kulturstiftung collects and uses
        personal data of website users only insofar as this is necessary for the
        provision of a functioning website as well as the contents and services
        provided there. The collection and use of users' personal data only
        takes place after the user has given his or her consent, unless
        obtaining consent in advance is not possible for actual reasons and the
        processing of the data is permitted by legal regulations.
      </p>
      <StandardParagraph>
        <Bold>Legal basis for the processing of personal data</Bold>
      </StandardParagraph>
      <p>
        Insofar as the data subject has given consent for the processing of
        his/her personal data, the processing is carried out on the legal basis
        of Art.6 para.1 lit.a DSGVO.
      </p>
      <p>
        In the case of processing of personal data for the performance of a
        contract to which the data subject is a party, Art.6 para.1 lit.b DSGVO
        is the legal basis for the processing of the data. This also applies to
        processing operations that are necessary for the performance of
        pre-contractual measures.
      </p>
      <p>
        If processing of personal data is necessary for compliance with a legal
        obligation to which the JES Kulturstiftung is subject, Art. 6 para.1
        lit.c DSGVO is the legal basis for the data processing.
      </p>
      <p>
        The processing of personal data is carried out on the legal basis of
        Art.6 para.1 lit.f DSGVO if it is necessary for the protection of a
        legitimate interest of the JES Kulturstiftung or a third party and the
        interests, fundamental rights and freedoms of the data subject do not
        override the former interest.
      </p>
      <StandardParagraph>
        <Bold>Data deletion and storage period</Bold>
      </StandardParagraph>
      <p>
        The personal data of the data subject will be deleted or blocked as soon
        as the purpose of storage ceases to apply. Storage may also take place
        if this has been provided for by the European or national legislator in
        Union regulations, laws or other provisions to which the JES
        Kulturstiftung is subject. The data will also be blocked or deleted if a
        storage period prescribed by the aforementioned standards expires,
        unless there is a need to continue storing the data.
      </p>
      <br /> <h3>B. PROVISION OF THE WEBSITE AND CREATION OF LOG FILES</h3>
      <StandardParagraph>
        <Bold>Description and scope of data processing</Bold>
      </StandardParagraph>
      <p>
        Each time the website of the JES Kulturstiftung is accessed, the
        underlying system automatically collects data and information from the
        computer system of the accessing computer. The following data is
        collected:
        <ul style={{ fontSize: "inherit" }}>
          <li style={{ fontSize: "inherit" }}>
            Information on the type of browser and the version used
          </li>
          <li style={{ fontSize: "inherit" }}>the user's operating system</li>
          <li style={{ fontSize: "inherit" }}>
            the user's internet service provider
          </li>
          <li style={{ fontSize: "inherit" }}>the IP address of the user</li>
          <li style={{ fontSize: "inherit" }}>date and time of access</li>
          <li style={{ fontSize: "inherit" }}>
            websites from which the user's system accesses the website of the
            JES Kulturstiftung
          </li>
          <li style={{ fontSize: "inherit" }}>
            websites accessed by the user's system via the JES Kulturstiftung
            website.
          </li>
        </ul>
        This data is also stored in the log files of the underlying system. This
        data is not stored together with other personal data of the user.
      </p>
      <StandardParagraph>
        <Bold>Legal basis for the processing of personal data</Bold>
      </StandardParagraph>
      <p>
        The legal basis for the temporary storage of the data and the log files
        is Art. 6 para. 1 lit. f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Purpose of data processing</Bold>
      </StandardParagraph>
      <p>
        The temporary storage of the IP address by the system is necessary to
        enable delivery of the website to the user's computer. For this purpose,
        the user's IP address must remain stored for the duration of the
        session. The storage in log files is done to ensure the functionality of
        the website. In addition, the data is used to optimise the website and
        to ensure the security of the information technology systems. An
        evaluation of the data for marketing purposes does not take place in
        this context. These purposes also constitute the legitimate interest of
        the JES Kulturstiftung in the processing of data in accordance with
        Art.6 Para.1 lit.f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Duration of storage</Bold>
      </StandardParagraph>
      <p>
        The data is deleted as soon as it is no longer required to achieve the
        purpose for which it was collected. In the case of the collection of
        data for the provision of the website, this is the case when the
        respective session has ended. In the case of storage of data in log
        files, this is the case after seven days at the latest. Storage beyond
        this period is possible. In this case, the IP addresses of the users are
        deleted or alienated, so that an assignment of the calling client is no
        longer possible.
      </p>
      <StandardParagraph>
        <Bold>Possibility of objection and removal</Bold>
      </StandardParagraph>
      <p>
        The collection of data for the provision of the website and the storage
        of the data in log files is absolutely necessary for the operation of
        the website. Consequently, the user has no possibility to object.
      </p>
      <br />
      <h3>C. COOKIES</h3>
      <StandardParagraph>
        <Bold>Description and scope of data processing</Bold>
      </StandardParagraph>
      <p>
        In order to optimally design the website for the user, the JES
        Kulturstiftung uses cookies, i.e. text files that are stored in the
        internet browser or by the internet browser on the user's computer
        system. When a user calls up a website, a cookie may be stored on the
        user's operating system. This cookie contains a characteristic string of
        characters that enables the browser to be uniquely identified when the
        website is called up again. The user data collected in this way is
        pseudonymised by technical precautions. Therefore, it is no longer
        possible to assign the data to the calling user. The data is not stored
        together with other personal data of the user.
      </p>
      <StandardParagraph>
        <Bold>Legal basis for data processing</Bold>
      </StandardParagraph>
      <p>
        The legal basis for the processing of personal data using this type of
        cookie is Art. 6 (1) lit. f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Purpose of data processing</Bold>
      </StandardParagraph>
      <p>
        The purpose of using technically necessary cookies is to simplify the
        use of websites for users. Some functions of the WEbsite cannot be
        offered without the use of cookies. For these, it is necessary that the
        browser is recognised even after a page change. These purposes also
        constitute the legitimate interest of the JES Kulturstiftung in the
        processing of personal data in accordance with Art. 6 Para. 1 lit. f
        DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Duration of storage, possibility of objection and removal</Bold>
      </StandardParagraph>
      <p>
        Cookies are stored on the user's computer and transmitted by it to the
        JES Kulturstiftung website. Therefore, the user also has full control
        over the use of cookies. By changing the settings in the Internet
        browser, the user can deactivate or restrict the transmission of
        cookies. Cookies that have already been stored can be deleted at any
        time. This can also be done automatically. If cookies are deactivated
        for this website, it may no longer be possible to use all functions of
        the website to their full extent.
      </p>
      <br />
      <h3>D. NEWSLETTER</h3>
      <StandardParagraph>
        <Bold>Description and scope of data processing</Bold>
      </StandardParagraph>
      <p>
        The newsletter is sent on the basis of a registration of the user on the
        website of the JES Kulturstiftung or because the JES Kulturstiftung has
        received an e-mail address with a request to send the newsletter. The
        website offers the possibility to subscribe to free newsletters. When
        subscribing to the newsletter, the data from the input mask is
        transmitted to the JES Kulturstiftung. The e-mail address, name,
        voluntary information, the IP address of the calling computer as well as
        the date and time of registration are transmitted at this point. For the
        processing of the data, the consent of the data subject is obtained
        during the registration process and reference is made to these notes on
        data processing.
      </p>
      <StandardParagraph>
        <Bold>Legal basis for data processing</Bold>
      </StandardParagraph>
      <p>
        The newsletter is sent on the basis of the user's registration on the
        website. The legal basis for the processing of the data after
        registration for the newsletter and consent of the user is Art. 6 para.
        1 lit. a DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Purpose of data processing</Bold>
      </StandardParagraph>
      <p>
        The collection of the user's e-mail address serves to deliver the
        newsletter. The newsletter is sent on the basis of the user's
        registration on the website. The collection of other personal data
        during the registration process serves to prevent misuse of the services
        or the e-mail address used and to personalise the newsletter. Any
        voluntary information is used to better understand how users become
        aware of the JES Cultural Foundation.
      </p>
      <StandardParagraph>
        <Bold>Disclosure of data to third parties</Bold>
      </StandardParagraph>
      <p>
        The JES Kulturstiftung has commissioned Mailchimp to send out the
        newsletter. There is an order processing contract with Mailchimp in
        accordance with Art.26 DSGVO, which guarantees the security of the
        personal data of the data subjects.
      </p>
      <StandardParagraph>
        <Bold>Duration of data storage</Bold>
      </StandardParagraph>
      <p>
        The data is deleted as soon as it is no longer required to achieve the
        purpose for which it was collected. Accordingly, the user's e-mail
        address is stored for as long as the subscription to the newsletter is
        active. The other personal data collected during the registration
        process is usually deleted after a period of seven days.
      </p>
      <StandardParagraph>
        <Bold>Possibility of objection and removal</Bold>
      </StandardParagraph>
      <p>
        The subscription to the newsletter can be cancelled by the user
        concerned at any time. For this purpose, a corresponding link can be
        found in each newsletter.
      </p>
      <br />
      <h3>E. RIGHTS OF THE DATA SUBJECT</h3>
      <p>
        Data subjects within the meaning of the GDPR, i.e. persons whose
        personal data is processed by the JES Cultural Foundation, have the
        following rights vis-Ã -vis the JES Cultural Foundation:
      </p>
      <StandardParagraph>
        <Bold>Right of withdrawal</Bold>
      </StandardParagraph>
      <p>
        The data subject has the right, insofar as special personal data are
        processed on the basis of his/her consent, to revoke this consent at any
        time vis-Ã -vis the JES Kulturstiftung in accordance with Art. 7 (3)
        DSGVO. To exercise this right of revocation, the data subject may
        contact JES Kulturstiftung. The revocation has the consequence that the
        data processing, which was based on this consent, may no longer be
        continued in the future.
      </p>
      <StandardParagraph>
        <Bold>Right to information</Bold>
      </StandardParagraph>
      <p>
        The data subject has the right to request information about his/her
        personal data processed by JES Kulturstiftung in accordance with Art. 15
        DSGVO. In particular, he or she may request information about the
        processing purposes, the category of personal data, the categories of
        recipients to whom his or her data have been or will be disclosed, the
        planned storage period, the existence of a right to rectification,
        erasure, restriction of processing or objection, the existence of a
        right of complaint, the origin of his or her data if this has not been
        collected by the JES Kulturstiftung, and about the existence of
        automated decision-making, including profiling, and, if applicable,
        meaningful information about its details.
      </p>
      <StandardParagraph>
        <Bold>Right of rectification</Bold>
      </StandardParagraph>
      <p>
        Pursuant to Art. 16 DSGVO, the data subject may request without undue
        delay the rectification of inaccurate or incomplete personal data stored
        by the JES Kulturstiftung.
      </p>
      <StandardParagraph>
        <Bold>Right of deletion</Bold>
      </StandardParagraph>
      <p>
        Pursuant to Art. 17 DSGVO, the data subject may request the erasure of
        his/her personal data stored by the JES Kulturstiftung, unless the
        processing is necessary for the exercise of the right to freedom of
        expression and information, for compliance with a legal obligation, for
        reasons of public interest, or for the establishment, exercise or
        defence of legal claims.
      </p>
      <StandardParagraph>
        <Bold>Right to restriction of data processing</Bold>
      </StandardParagraph>
      <p>
        The data subject may, in accordance with Art. 18 DSGVO, request the
        restriction of the processing of his/her personal data, insofar as the
        accuracy of the data is contested by him/her, the processing is
        unlawful, but he/she objects to its erasure and the JES Kulturstiftung
        no longer needs the data, but he/she requires it for the assertion,
        exercise or defence of legal claims, or he/she has objected to the
        processing in accordance with Art. 21 DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Right to transfer data</Bold>
      </StandardParagraph>
      <p>
        The data subject has the right to obtain, in accordance with Art. 20
        DSGVO, his/her personal data provided to the JES Kulturstiftung in a
        structured, commonly used and machine-readable format, or to request
        that it be transferred to another controller.
      </p>
      <StandardParagraph>
        <Bold>Right to complain</Bold>
      </StandardParagraph>
      <p>
        The data subject has the right to complain to a supervisory authority in
        accordance with Art. 77 DSGVO. As a rule, he or she may contact the
        supervisory authority of his or her usual place of residence or place of
        work or the registered office of the JES Kulturstiftung for this
        purpose.
      </p>
      <StandardParagraph>
        <Bold>Right of objection</Bold>
      </StandardParagraph>
      <p>
        Insofar as the personal data of the data subject are processed on the
        basis of legitimate interests pursuant to Article 6 (1) sentence 1 lit.
        f DSGVO, he or she has the right to object to the processing of his or
        her personal data pursuant to Article 21 DSGVO, insofar as there are
        grounds for doing so which arise from his or her particular situation.
        To exercise this right of objection, it is sufficient to send an e-mail
        to{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
        .
      </p>
      <q>Status: 22.01.2021</q>
    </JesLayout>
  )
}

export default DatenschutzENPage

export const query = graphql`
  query DatenschutzENPageQuery($language: String!) {
    markdownRemark(frontmatter: { meta: { eq: "impressum" } }) {
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
